<template>
  <section class="tables">
    <div class="page-header">
      <h3 class="page-title">
        Search Terms
      </h3>
      <div class="btn-group" role="group" aria-label="Basic example">
        <b-form-input type="text" class="searchInput" @keypress="handleKeyPress" placeholder="Search..."
          v-model="keyword"></b-form-input>
        <b-button @click="getResults()" class="btn btn-primary"><i class="fa fa-search"></i>
        </b-button>
        <b-button @click="clearSearchInput()" class="mr-4 btn btn-danger"><i class="fa fa-trash-o"></i>
        </b-button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h1 v-if="empty_data_error != ''">{{ empty_data_error }}</h1>
            <table v-else class="table table-hover table-striped table-responsive" id="audios_table">
              <thead>
                <tr class="frown" style="color:#303030 !important">
                  <th scope="col" style="width: 20%">#</th>
                  <th scope="col" style="width: 40%">Search Term</th>
                  <th scope="col" style="width: 20%">Count</th>
                  <th scope="col" style="width: 20%">Created Date</th>
                  <th scope="col" style="width: 20%">Actions</th>
                </tr>
              </thead>
              <tbody style="color:#696969 !important;">
                <tr v-for="(item, index) in items" :key="item.id">
                  <td scope="row" class="sorting_1">{{ index + 1 }}</td>
                  <td>
                    <p class="bigText">
                      {{ item.queries }}
                    </p>
                  </td>

                  <td>
                    <p class="bigText">
                      {{ item.count }}
                    </p>
                  </td>

                  <td>
                    <p class="bigText">
                      {{ getFormattedTime(item.created_at) }}
                    </p>
                  </td>
                  <td>
                    <button class="btn btn-warning" @click="showEditModal(
                      item.id,
                      item.queries,
                      item.count,
                    )">
                      <i class="fa fa-pencil"></i>
                    </button>

                    <button class="btn btn-danger"  @click="showAlert(item.id)">
                      <i class="fa fa-trash"></i>
                    </button>
                    
                  </td>
                </tr>
              </tbody>
            </table>

            <div v-if="!searchButtonClicked &&  empty_data_error == ''" class="row">
              <div class="col-md-6 pageInfoBox">
                Total records : {{ total_data }}
                | Total pages : {{ items_total }}
                | Current page : {{ page }}
                <!-- <input type="number" min="1" class="pageInfoInput" v-model="page" />  -->
              </div>
              <div class="col-md-6 text-right">
                <button class="btn btn-success" :disabled="pagiBackButtonDisabled" @click="getData('previous')"><i
                    class="fa fa-arrow-left" aria-hidden="true"></i></button>
                <button class="btn btn-success" :disabled="pagiNextButtonDisabled" @click="getData('next')"><i
                    class="fa fa-arrow-right" aria-hidden="true"></i></button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div>
    </div>
    <!-- <b-modal ref="add-modal" id="modalmd" title="Add Audio" size="lg" hide-footer>
        <AudioAddModal />
      </b-modal>
  
      <b-modal ref="edit-modal" id="modalmd" title="Edit Audio" size="lg" hide-footer>
        <AudioEditModal />
      </b-modal> -->

    <vue-snotify></vue-snotify>
  </section>
</template>
<script>
//   import AudioEditModal from "../modals/audio-modals/AudioEditModal.vue";
//   import AudioAddModal from "../modals/audio-modals/AudioAddModal.vue";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import api from "../../config/api.js";
import Vue from "vue";
// import SortedTablePlugin from "vue-sorted-table";
import $ from "jquery";

export default {
  components: {
    //   AudioEditModal,
    //   AudioAddModal,
  },

  async created() {
    //   console.log('data dome1')
    this.$root.$refs.Audio = this;
    this.category_id = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
    // await this.$store.dispatch(`${this.contentType.name}/all`);
    if (this.$store.getters['getMessage']) {
      let obj = this.$store.getters['getMessage'];
      this.$swal('Success!', obj.msg, 'success')
      // this.showSnotifySuccess(obj.msg)
      this.$store.dispatch('setMessage', "");
    }
  },
  data: function () {
    return {
      baseUrl: "", // search & pagination work
      searchedUrl: "", // search & pagination work
      searchButtonClicked: false, // search & pagination work
      keyword: "", // search & pagination work
      pagiBackButtonDisabled: true, // search & pagination work
      pagiNextButtonDisabled: false, // search & pagination work
      page: 0, // search & pagination work
      items_total: 0, // search & pagination work
      total_data: 0, // search & pagination work
      keywordEmptyAfterDelete: false, // search & pagination work
      items: [], // search & pagination work

      data_table: null,
      empty_data_error: "",
      computed_count: 0,
      category_id: null,
    };
  },
  mounted() {
    this.getData('next');
  },


  watch: {
    // search & pagination work
    keyword(after, before) {
      if (after != "") {
        if (this.items.length > 0) {
          this.empty_data_error = "";
        }
      } else if (after == "" && !this.keywordEmptyAfterDelete) {
        this.searchButtonClicked = false;
        this.getData();
      }
    },
    // search & pagination work
  },

  methods: {

    handleKeyPress(event) {
      if (event.key == "Enter" && this.keyword != "") {
        this.getResults()
      }
    },

    clearSearchInput() {
      this.keyword = "";
      this.keywordEmptyAfterDelete = false;
    },

    getResults() {
      if (this.keyword != "") {
        this.searchButtonClicked = true;
        api.get('/admin/livesearch-search-term', { params: { keyword: this.keyword } })
          .then(res => {
            console.log('get results data ::: ', res.data);
            this.items = res?.data
            if (this.items.length == 0) {
              this.empty_data_error = "Data Not Found!"
            } else {
              this.empty_data_error = "";
            }
          })
          .catch(error => console.log("getResults : ", error));
        console.log(this.items);
      }
    },

    async getData($info = null) {
      this.searchButtonClicked = false;
      if (this.$store.state.pageNumberAfterAction != "") {
        console.log("store data :::: ", this.$store.state.pageNumberAfterAction);
        this.page = this.$store.state.pageNumberAfterAction;
        this.$store.state.pageNumberAfterAction = "";
      } else if ($info == "next") {
        this.page = ++this.page;
      } else if ($info == "previous") {
        this.page = --this.page;
      }

      console.log("page watch : ", this.page);
      console.log("page watch items lenght : ", this.items.length);
      console.log("page watch items lenght orignal: ", this.items_total);

      console.log("get Data page num : ", this.page);
      let result = await api.get(`/admin/all-search-terms?page=${this.page}`);
      if (result.status == 200) {
        console.log("new audios data :::: ", result.data);
        this.items = result?.data?.all_searches;
        this.items_total = result?.data?.total;
        this.total_data = result?.data?.total_data;
        if (this.items.length > 0) {
          this.empty_data_error = "";
          this.pagiBackButtonDisabled = (this.page > 1) ? false : true;
          this.pagiNextButtonDisabled = (this.page >= this.items_total) ? true : false;
        }
        else {
          this.empty_data_error = "Data Not Found!"
        }

      }
    },


    showAlert(id) {
      try {
        let endpoint2 = `/admin/delete-search-term/`;
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then(async (res) => {
          if (res.value) {
            let result = await api.delete(endpoint2 + id);
            if (result.status == 200) {
              this.getData();
              this.$swal(
                'Deleted!',
                'Search Term has been deleted.',
                'success'
              ).then(() => {
                this.keyword = "";
                this.keywordEmptyAfterDelete = true;
              })
            }
          }
        })
      } catch (error) {
        console.log(error)
      }
    },

    async showEditModal(id, queries, count){
      await this.$store.dispatch("setDataInEditForm", {
        "pageNumber": this.page,
        "type": "search",
        id, queries, count
      });

      var obj = {
        "pageNumber": this.page,
        "type": "search",
        id, queries, count
      };
      localStorage.setItem('data_for_edit', JSON.stringify(obj));
      const url = `/all-search-terms/${id}/edit/`;
      this.$router.push({ path: url });
    },

    
    goBack() {
      let prev_url = "/ebooks/list";
      this.$router.push({ path: prev_url });
    },
    async showAddModal() {
      await this.$store.dispatch('setDataInEditForm',
        { "type": this.contentType.name })
      // this.$refs["add-modal"].show();
      const url = `/promocode/add`;
      console.log(url);
      this.$router.push({ path: url });
    },
  },
}
</script>
  
<style>
.bigText {
  /* display: inline-block; */
  /* width: 180px; */
  /* white-space: nowrap; */
  /* overflow: hidden !important; */
  /* text-overflow: ellipsis; */
}

.modalDesc {
  padding: 20px 40px;
  max-height: 700px;
  overflow-y: scroll;
}

/* #audios_table .btn-secondary{
    background-color: #3bccb0 !important;
    border-color:  #3bccb0 !important;
  } */
</style>
  